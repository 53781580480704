import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6742 14.9476L14.1818 15.8182C11.1469 14.2953 9.27273 12.5455 8.18182 9.81818L9.02182 5.31273L7.43455 1.09091H3.34255C2.112 1.09091 1.14328 2.10764 1.32764 3.324C1.78582 6.36 3.13855 11.8658 7.09091 15.8182C11.2418 19.9691 17.2211 21.7702 20.5113 22.4869C21.7822 22.7629 22.9091 21.7724 22.9091 20.4709V16.5611L18.6742 14.9476Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneIcon;
